import React from 'react';

import { Tab } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import CreditPurchases from '../pages/CreditPurchases.jsx';
import Deliveries from '../pages/Deliveries';
import UsersTable from '../pages/Users';
// import Withdrawals from '../pages/Withdrawals';

const tabs = [
  {
    name: 'Users',
    url: '/control-panel/users',
    content: <UsersTable />
  },
  {
    name: 'Deliveries',
    url: '/control-panel/deliveries',
    content: <Deliveries />
  },
  // {
  //   name: 'Withdrawals',
  //   url: '/control-panel/withdrawals',
  //   content: <Withdrawals />
  // },
  {
    name: 'Credit Purchases',
    url: '/control-panel/purchases',
    content: <CreditPurchases />
  }
];

const Navigation = () => {
  const navigate = useNavigate();

  function handleTabSelect(index) {
    const { url } = tabs[index];
    navigate(url);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="w-full px-2">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl" as="div">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.name}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-1.5 text-sm font-medium leading-5',
                  'ring-white focus:outline-none',
                  selected ? 'bg-blue text-white shadow' : 'text-blue border-2 border-blue hover:bg-blue hover:text-white'
                )
              }
              onClick={() => handleTabSelect(index)}>
              {tab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-10">
          {tabs.map((tab) => (
            <Tab.Panel key={tab.name} className={classNames('rounded-xl bg-white', 'ring-white focus:outline-none')}>
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Navigation;
